<template>
	<!-- Loading -->
	<ActionsLoading v-if="isLoading" />
	<div v-else class="list-container no-sub-nav">
		<div
			class="no-list vertical-center"
			v-if="
				critical.length == 0 &&
				timeCritical.length == 0 &&
				nonCritical.length == 0
			"
		>
			<div class="icon">
				<fa icon="clipboard-check" />
			</div>
			<p>No actions found</p>
		</div>

		<!-- Critical actions -->
		<div v-if="critical && critical.length > 0">
			<div class="row">
				<div class="action-list-header">
					<div class="circle-avatar critical">
						<fa class="ex-tri" icon="exclamation-triangle" />
					</div>
					<div>
						<h3>Critical:</h3>
					</div>
				</div>
			</div>
			<div class="table-row-container">
				<!-- Critical Actions -->
				<div v-for="action in critical" :key="action.id">
					<ActionListRow :action="action" />
				</div>
			</div>
		</div>

		<!-- Time-Critical actions -->
		<div v-if="timeCritical && timeCritical.length > 0">
			<div class="spacer-20"></div>
			<div class="row">
				<div class="action-list-header">
					<div class="circle-avatar time-critical">
						<fa class="ex-tri" icon="exclamation-triangle" />
					</div>
					<div>
						<h3>Time-Critical:</h3>
					</div>
				</div>
			</div>
			<div class="table-row-container">
				<!-- Time-Critical Checklist Actions -->
				<div v-for="action in timeCritical" :key="action.id">
					<ActionListRow :action="action" />
				</div>
			</div>
		</div>

		<!-- Non-Critical actions -->
		<div v-if="nonCritical && nonCritical.length > 0">
			<div class="spacer-20"></div>
			<div class="row">
				<div class="action-list-header">
					<div class="circle-avatar non-critical">
						<fa class="ex-tri" icon="exclamation-triangle" />
					</div>
					<div>
						<h3>Non-Critical:</h3>
					</div>
				</div>
			</div>
			<div class="table-row-container">
				<!-- Non-Critical Checklist Actions -->
				<div v-for="action in nonCritical" :key="action.id">
					<ActionListRow :action="action" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/reactivity";
import account from "../../composables/account";
import { projectFirestore } from "../../firebase/config";
import getCollection from "../../composables/getCollection";
import ActionsLoading from "../../components/actions/ActionsLoading.vue";
import ActionListRow from "../../components/actions/ActionListRow.vue";
import Alert from "../../components/Alert.vue";
import { computed, watchEffect, watch } from "vue";

export default {
	name: "ActionsList",
	components: { ActionsLoading, Alert, ActionListRow },
	props: ["actionsList"],
	setup(props) {
		const { user, companyId } = account();
		const actions = ref(null);
		const filteredActions = ref([]);
		const isLoading = ref(true);

		// Stream all actions relevant to the user
		const ownedChecklistActionsRef = projectFirestore
			.collection("Action")
			.where("ownerId", "==", user.value.uid);
		const assignedChecklistActionsRef = projectFirestore
			.collection("Action")
			.where("assignedPerson.id", "==", user.value.uid);
		const ownedEquipmentActionsRef = projectFirestore
			.collection("EquipmentAction")
			.where("ownerId", "==", user.value.uid);
		const assignedEquipmentActionsRef = projectFirestore
			.collection("EquipmentAction")
			.where("assignedPerson.id", "==", user.value.uid);
		const commentingActionsRef = projectFirestore
			.collection("Action")
			.where("commentors", "array-contains", user.value.uid);
		const commentingEquipmentActionsRef = projectFirestore
			.collection("EquipmentAction")
			.where("commentors", "array-contains", user.value.uid);
		const allChecklistActionsRef = projectFirestore
			.collection("Action")
			.where("companyId", "==", companyId);
		const allEquipmentActionsRef = projectFirestore
			.collection("EquipmentAction")
			.where("companyId", "==", companyId);

		const { documents: ownedChecklistActions } = getCollection(
			ownedChecklistActionsRef
		);
		const { documents: assignedChecklistActions } = getCollection(
			assignedChecklistActionsRef
		);
		const { documents: ownedEquipmentActions } = getCollection(
			ownedEquipmentActionsRef
		);
		const { documents: assignedEquipmentActions } = getCollection(
			assignedEquipmentActionsRef
		);
		const { documents: commentingActions } =
			getCollection(commentingActionsRef);
		const { documents: commentingEquipmentActions } = getCollection(
			commentingEquipmentActionsRef
		);
		const { documents: allChecklistActions } = getCollection(
			allChecklistActionsRef
		);
		const { documents: allEquipmentActions } = getCollection(
			allEquipmentActionsRef
		);

		function convertDateStringToDate(date) {
			let assignedDate = new Date();

			if (date.includes("-")) {
				assignedDate = new Date(date);
			} else if (date.includes("/")) {
				const dateParts = date.split("/");
				assignedDate = new Date(
					parseInt(dateParts[2], 10),
					parseInt(dateParts[0], 10) - 1, // Month is 0-based in JavaScript Date
					parseInt(dateParts[1], 10)
				);
			}
			return assignedDate;
		}

		watchEffect(() => {
			if (
				ownedChecklistActions.value &&
				assignedChecklistActions.value &&
				ownedEquipmentActions.value &&
				assignedEquipmentActions.value &&
				commentingActions.value &&
				commentingEquipmentActions.value &&
				allChecklistActions.value &&
				allEquipmentActions.value
			) {
				filteredActions.value = [];
				isLoading.value = false;

				actions.value = [
					...ownedChecklistActions.value,
					...assignedChecklistActions.value,
					...ownedEquipmentActions.value,
					...assignedEquipmentActions.value,
					...commentingActions.value,
					...commentingEquipmentActions.value,
					...allChecklistActions.value,
					...allEquipmentActions.value,
				];

				// Remove duplicates e.g. owned and assigned to the same person
				actions.value = actions.value.reduce((unique, action) => {
					return unique.some((u) => u.id === action.id)
						? unique
						: [...unique, action];
				}, []);

				// Filter actions based on the list type
				if (props.actionsList === "OwnedActionsList") {
					filteredActions.value = actions.value.filter(
						(action) =>
							action.ownerId === user.value.uid &&
							action.status !== "Complete"
					);
				} else if (props.actionsList === "AssignedActionsList") {
					filteredActions.value = actions.value.filter(
						(action) =>
							action.assignedPerson.id === user.value.uid &&
							action.status !== "Complete"
					);
				} else if (props.actionsList === "CompletedActionsList") {
					filteredActions.value = actions.value.filter(
						(action) =>
							(action.assignedPerson.id === user.value.uid ||
								action.ownerId == user.value.uid) &&
							action.status === "Complete"
					);
				} else if (props.actionsList === "CommentingActionsList") {
					filteredActions.value = actions.value.filter(
						(action) =>
							action.commentors &&
							Array.isArray(action.commentors) &&
							action.status !== "Complete" &&
							action.commentors.includes(user.value.uid)
					);
				} else if (props.actionsList === "AllActionsList") {
					filteredActions.value = actions.value.filter(
						(action) => action.status !== "Complete"
					);
				}

				// Sort actions by assignedDate using the convertDateStringToDate function
				filteredActions.value.sort(
					(a, b) =>
						convertDateStringToDate(a.assignedDate) -
						convertDateStringToDate(b.assignedDate)
				);
			}
		});

		const critical = computed(() => {
			if (filteredActions.value) {
				return filteredActions.value.filter(
					(action) => action.state === "Critical"
				);
			}
		});

		const timeCritical = computed(() => {
			if (filteredActions.value) {
				return filteredActions.value.filter(
					(action) => action.state === "Time-Critical"
				);
			}
		});

		const nonCritical = computed(() => {
			if (filteredActions.value) {
				return filteredActions.value.filter(
					(action) => action.state === "Non Critical"
				);
			}
		});

		return {
			isLoading,
			filteredActions,
			critical,
			timeCritical,
			nonCritical,
		};
	},
};
</script>
