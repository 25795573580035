<template>
	<div class="modal">
		<div class="modal-container">
			<form class="rm-form" @submit.prevent v-if="action">
				<div class="modal-content tile">
					<div class="modal-header">
						<div class="left">
							<div class="modal-title">
								<div
									class="circle-avatar"
									v-bind:class="{
										critical: action.state == 'Critical',
										timeCritical:
											action.state == 'Time-Critical',
										nonCritical:
											action.state == 'Non Critical',
									}"
								>
									<fa
										class="action-icon"
										icon="exclamation-triangle"
									/>
								</div>
								<h3>{{ action.state }} Action&nbsp;</h3>
								<p v-if="owner">
									Created by {{ owner.fullName }} on
									{{
										action.createdAt
											.toDate()
											.toLocaleString("en-US", options)
									}}
								</p>
							</div>
						</div>
						<div class="right">
							<div class="form-options">
								<button
									class="btn-icon"
									@click="viewChecklist(action.checklistId)"
									v-if="action.checklistId && !updating"
								>
									<div class="text">View Checklist</div>
									<fa class="icon" icon="external-link-alt" />
								</button>
								<button
									class="btn-icon"
									v-if="!updating"
									@click="updateAction"
								>
									<div class="text">Close</div>
									<fa class="icon" icon="times" />
								</button>
								<PageLoading v-if="updating" />
							</div>
						</div>
					</div>
					<div class="split">
						<div class="container-left">
							<div class="form-input">
								<label for>Description:</label>
								<textarea
									v-if="!quillDelta"
									v-model="description"
									:disabled="!assigned"
									ref="textarea"
								></textarea>
								<QuillEditor
									v-else
									ref="textEditor"
									theme="snow"
									:toolbar="toolbarOptions"
									placeholder="Instructions text..."
									style="
										font-family: 'Poppins';
										font-size: 1em;
										max-height: 40vh;
										overflow: auto;
									"
									v-model:content="quillDelta"
								/>
							</div>
							<div class="split">
								<div class="form-input">
									<label for>Priority:</label>
									<select
										v-model="selectedState"
										:disabled="!assigned"
									>
										<option>Critical</option>
										<option>Time-Critical</option>
										<option>Non Critical</option>
									</select>
								</div>
								<div class="form-input">
									<label for>Due Date:</label>
									<input
										v-if="dueDate"
										class="date-input"
										type="date"
										v-model="dueDate"
										:disabled="!assigned"
									/>
								</div>
							</div>
							<div class="split">
								<div
									class="form-input"
									v-if="
										selectedUser &&
										employees != null &&
										employees.length
									"
								>
									<label>Assigned To:</label>
									<select
										v-model="selectedUser"
										:disabled="!assigned"
									>
										<option
											v-for="employee in employees"
											:key="employee.id"
											:value="employee.id"
										>
											{{ employee.profile.fullName }}
										</option>
									</select>
								</div>
								<div class="form-input">
									<label for>Status:</label>
									<select v-model="selectedStatus">
										<option>Not Started</option>
										<option>In Progress</option>
										<option>Complete</option>
									</select>
								</div>
							</div>

							<div class="split" v-if="thread">
								<div
									class="form-input"
									v-if="commentors && commentors.length"
								>
									<label for>Can comment:</label>
									<div class="selected-users">
										{{ commentors[0].name }} &
										<span
											class="tooltip"
											:title="allCommentors"
										>
											{{ commentors.length - 1 }}
											{{
												commentors.length > 2
													? "others"
													: "other"
											}}...
										</span>
									</div>
								</div>
								<div
									class="form-input edit-selected-users"
									v-if="assigned"
								>
									<div class="flex" v-if="showUpdateIcons">
										<fa
											v-if="
												commentorOptions &&
												commentorOptions.length
											"
											class="btn btn-icon"
											icon="user-plus"
											@click="
												handleUpdateCommentorType('add')
											"
										/>
										<fa
											class="btn btn-icon"
											icon="user-minus"
											@click="
												handleUpdateCommentorType(
													'remove'
												)
											"
										/>
									</div>
									<div v-else>
										<div
											class="flex loading-container"
											v-if="updatingCommentors"
										>
											<PageLoading />
										</div>

										<div class="flex" v-else>
											<vue-select
												v-if="showAddCommentor"
												v-model="value"
												:options="commentorOptions"
												label-by="name"
												searchable
												clear-on-select
												clear-on-close
												close-on-select
												search-placeholder="Type & select..."
												placeholder="Add User..."
											>
											</vue-select>
											<vue-select
												v-else
												v-model="value"
												:options="commentors"
												label-by="name"
												searchable
												clear-on-select
												clear-on-close
												close-on-select
												search-placeholder="Type & select..."
												placeholder="Remove User..."
											>
											</vue-select>
											<fa
												v-if="showAddCommentor"
												class="btn btn-icon"
												icon="save"
												@click="updateCommentor('add')"
											/>
											<fa
												v-else
												class="btn btn-icon"
												icon="save"
												@click="
													updateCommentor('remove')
												"
											/>
											<fa
												class="btn btn-icon"
												icon="times"
												@click="handleShowUpdateIcons"
											/>
										</div>
									</div>
								</div>
							</div>

							<!-- Checklist content -->
							<div class="form-input" v-if="company && checklist">
								<label for>Location:</label>
								<div class="action-detail">
									<p class="semi-bold">
										{{ company.companyName }} >
										{{ checklist.location.name }}
									</p>
								</div>
							</div>
							<div
								class="form-input"
								v-if="
									action.checklistId &&
									checklistPage &&
									checklistSection
								"
							>
								<label class="flex">
									<span class="truncate"
										>{{ action.checklistName }}&nbsp;
									</span>
									>
									<span class="truncate">
										&nbsp;{{ checklistPage.title }}&nbsp;
									</span>
									>
									<span class="truncate">
										&nbsp;{{ checklistSection.title }}&nbsp;
									</span>
									>
								</label>
								<div class="action-detail">
									<Question
										class="margin-top"
										v-if="checklistQuestion"
										:question="checklistQuestion"
									/>
								</div>
							</div>

							<!-- Equipment Content -->
							<div
								v-if="
									attachedEquipment &&
									attachedEquipment.length > 0
								"
							>
								<div class="form-input">
									<label>Equipment:</label>
									<div
										class="table-row-container"
										v-bind:class="{
											'attached-equipment-container':
												attachedEquipment.length > 3,
										}"
										style="margin-top: 0.5em"
									>
										<div
											class="table-row"
											v-for="item in attachedEquipment"
											:key="item.id"
										>
											<div class="row-left">
												<EquipmentRowLeft
													:item="item"
													:canEdit="false"
												/>
											</div>

											<div class="row-right">
												<EquipmentRowRight
													:item="item"
													:canEdit="canEditEquipment"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- Legacy Equipment Action -->
							<div v-if="equipment">
								<div class="form-input" v-if="company">
									<label for>Location:</label>
									<div class="action-detail">
										<p class="semi-bold">
											{{ company.companyName }} >
											{{ equipment.locationName }}
										</p>
									</div>
								</div>
								<div class="form-input">
									<label>Item:</label>
									<div class="table-row-container">
										<div class="table-row">
											<div class="row-left">
												<EquipmentRowLeft
													:item="equipment"
													:canEdit="false"
												/>
											</div>

											<div class="row-right">
												<EquipmentRowRight
													:item="equipment"
													:canEdit="canEditEquipment"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- Action photos -->
							<div
								class="form-input"
								v-if="
									checklistQuestion &&
									checklistQuestion.actionPhotos &&
									checklistQuestion.actionPhotos.length > 0
								"
							>
								<label for>Images:</label>
								<div class="images">
									<div class="image-grid">
										<div
											v-for="photo in checklistQuestion.actionPhotos"
											:key="photo"
										>
											<div
												class="image"
												v-if="!photo.startsWith('u')"
											>
												<router-link
													:to="{
														name: 'ChecklistImageView',
														query: {
															photo: photo,
														},
													}"
												>
													<img
														crossorigin="*"
														v-bind:src="photo"
													/>
												</router-link>
											</div>
											<div class="image" v-else>
												<img
													src="@/assets/image-placeholder.png"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- Action Notes -->
							<div
								class="form-input"
								v-if="
									checklistQuestion && checklistQuestion.notes
								"
							>
								<label for>Notes:</label>
								<div class="action-detail">
									<p>
										{{ checklistQuestion.notes }}
									</p>
								</div>
							</div>
						</div>
						<!-- Right column -->
						<div style="width: 80%">
							<Chat v-if="thread" :threadId="thread.id" />
							<div v-else class="start-chat">
								<h3>Start a conversation</h3>
								<p>
									Add private comments, images and files to
									this action.
								</p>
								<br />
								<button
									class="btn btn-primary"
									@click="createChatThread"
								>
									Start chat
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { onMounted, ref, computed, defineComponent } from "vue";
import { useMeta } from "vue-meta";
import { useRouter } from "vue-router";

// Firebase
import {
	projectFirestore,
	addArrayItem,
	removeArrayItem,
} from "../../firebase/config";

// Composables
import getDocumentOnce from "../../composables/getDocumentOnce";
import account from "../../composables/account";
import chatService from "../../composables/chatService";
import equipmentCollection from "../../composables/equipmentsCollection";

// Components
import PageLoading from "../../components/PageLoading.vue";
import Question from "../../components/checklists/questions/Question.vue";
import EquipmentRowLeft from "../../components/equipment/EquipmentRowLeft.vue";
import EquipmentRowRight from "../../components/equipment/EquipmentRowRight.vue";
import EquipmentRowTags from "../../components/equipment/EquipmentRowTags.vue";
import Chat from "../../components/chat/Chat.vue";

import { QuillEditor } from "@vueup/vue-quill";
import Delta from "quill-delta";

export default defineComponent({
	name: "ActionDetail",
	props: ["companyId", "collection", "id"],
	components: {
		PageLoading,
		Question,
		EquipmentRowLeft,
		EquipmentRowRight,
		EquipmentRowTags,
		Chat,
		QuillEditor,
	},
	setup(props) {
		useMeta({ title: "Action details" });

		const router = useRouter();
		const { createThread } = chatService();
		const { getEquipmentByIds } = equipmentCollection();

		// Data
		const { user } = account();
		const action = ref(null);
		const company = ref(null);
		const owner = ref(null);
		const employees = ref(null);
		const checklist = ref(null);
		const checklistPage = ref(null);
		const checklistSection = ref(null);
		const checklistQuestion = ref(null);
		const equipment = ref(null);
		const attachedEquipment = ref(null);
		const thread = ref(null);

		// Form refs
		const description = ref(null);
		const quillDelta = ref(null);
		const selectedState = ref(null);
		const dueDate = ref(null);
		const selectedUser = ref(null);
		const selectedStatus = ref("");

		const value = ref([]);
		const commentors = ref([]);
		const showAddCommentor = ref(true);
		const showUpdateIcons = ref(true);
		const updatingCommentors = ref(false);
		const allUsers = ref([]);

		const updating = ref(false);
		const canEditEquipment = ref(false);

		let options = {
			weekday: "short",
			year: "numeric",
			month: "short",
			day: "numeric",
		};

		const toolbarOptions = [
			["bold", "italic", "underline"], // toggled buttons
			["blockquote"],
			["link"],

			[{ list: "ordered" }, { list: "bullet" }, { list: "check" }],

			[{ header: [1, 2, 3, 4, 5, 6, false] }],
		];

		const actionRef = projectFirestore
			.collection(props.collection)
			.doc(props.id);

		const threadRef = projectFirestore
			.collection("threads")
			.where("actionId", "==", props.id);

		onMounted(async () => {
			const { document } = await getDocumentOnce(actionRef);
			action.value = document.value;

			if (action.value.document) {
				const jsonContent = action.value.document;
				quillDelta.value = new Delta(JSON.parse(jsonContent));
			}

			description.value = action.value.description;
			selectedStatus.value = action.value.status;
			selectedState.value = action.value.state;
			selectedUser.value = action.value.assignedPerson.id;

			await getOwner(action.value.ownerId);

			if (action.value.identifier) {
				let equipmentIds = [action.value.identifier];
				const fetchedEquipment = await getEquipmentByIds(
					action.value.companyId,
					equipmentIds
				);
				attachedEquipment.value = fetchedEquipment;
			}

			if (
				action.value.attachedEquipment &&
				action.value.attachedEquipment.length > 0
			) {
				let equipmentIds = action.value.attachedEquipment.map(
					(equipment) => equipment.id
				);
				let equipmentCompanyId =
					action.value.attachedEquipment[0].companyId;

				const fetchedEquipment = await getEquipmentByIds(
					equipmentCompanyId,
					equipmentIds
				);
				attachedEquipment.value = fetchedEquipment;
			}

			// Format the dueDate
			if (action.value.assignedDate.includes("/")) {
				let originalDate = action.value.assignedDate;
				let parts = originalDate.split("/");
				let newDate =
					parts[2] + "-" + pad(parts[0], 2) + "-" + pad(parts[1], 2);

				dueDate.value = newDate;
			} else {
				dueDate.value = action.value.assignedDate;
			}

			if (action.value.checklistId) {
				getChecklistDetails();
				getChecklistAssignees();
			}

			if (
				action.value.identifier ||
				(action.value.attachedEquipment &&
					action.value.attachedEquipment.length > 0)
			) {
				getEquipmentAssignees();
			}

			const result = await threadRef.get();

			if (result.docs.length > 0) {
				thread.value = {
					id: result.docs[0].id,
					...result.docs[0].data(),
				};
			}

			const companyIds = [];

			if (thread.value) {
				const threadUserIds = thread.value.users;

				const usersRef = projectFirestore.collection("users");

				for (const userId of threadUserIds) {
					const { document: user } = await getDocumentOnce(
						usersRef.doc(userId)
					);

					if (user.value) {
						// Add user to commentors
						const userOption = {
							id: user.value.id,
							name: user.value.fullName,
						};
						commentors.value.push(userOption);

						// Add company to companyIds
						if (!companyIds.includes(user.value.companyId)) {
							companyIds.push(user.value.companyId);
						}
					}
				}

				for (const companyId of companyIds) {
					const usersSnapshot = await usersRef
						.where("companyId", "==", companyId)
						.get();
					usersSnapshot.forEach((doc) => {
						const user = doc.data();

						const userOption = {
							id: doc.id,
							name: user.fullName,
						};
						allUsers.value.push(userOption);
					});
				}
			}
		});

		const getOwner = async () => {
			const ownerRef = projectFirestore
				.collection("users")
				.doc(action.value.ownerId);
			const { document: ownerDoc } = await getDocumentOnce(ownerRef);
			owner.value = ownerDoc.value;

			if (owner.value.companyId == action.value.companyId) {
				canEditEquipment.value = true;
			}
		};

		const getChecklistDetails = async () => {
			const checklistPageRef = projectFirestore
				.collection("Checklist")
				.doc(action.value.checklistId)
				.collection("Template")
				.doc(action.value.templateId)
				.collection("Pages")
				.doc(action.value.pageId);
			const { document: checklistPageDoc } = await getDocumentOnce(
				checklistPageRef
			);
			checklistPage.value = checklistPageDoc.value;

			const checklistSectionRef = checklistPageRef
				.collection("Sections")
				.doc(action.value.sectionId);
			const { document: checklistSectionDoc } = await getDocumentOnce(
				checklistSectionRef
			);
			checklistSection.value = checklistSectionDoc.value;

			const checklistQuestionRef = checklistSectionRef
				.collection("Questions")
				.doc(action.value.questionId);
			const { document: checklistQuestionDoc } = await getDocumentOnce(
				checklistQuestionRef
			);
			checklistQuestion.value = checklistQuestionDoc.value;
		};

		const getChecklistAssignees = async () => {
			const checklistRef = projectFirestore
				.collection("Checklist")
				.doc(action.value.checklistId);

			const { document: checklistDoc } = await getDocumentOnce(
				checklistRef
			);

			checklist.value = checklistDoc.value;

			let companyId = checklist.value.companyId;

			if (checklist.value.thirdPartyCompanyId) {
				companyId = checklist.value.thirdPartyCompanyId;
			}

			const companyRef = projectFirestore
				.collection("companies")
				.doc(companyId);

			// Get employees of the company
			const employeesRef = companyRef
				.collection("employees")
				.where("isActive", "==", true)
				.where(
					"locations",
					"array-contains",
					checklist.value.location.id
				);

			employeesRef.get().then((querySnapshot) => {
				const employeeList = [];
				querySnapshot.forEach((doc) => {
					const employee = { id: doc.id, ...doc.data() };
					employeeList.push(employee);
				});
				employees.value = employeeList;
			});

			// Get inspectors of the company
			const inspectorsRef = companyRef
				.collection("inspector")
				.where("status", "==", "Accepted");

			inspectorsRef.get().then((querySnapshot) => {
				const inspectorList = [];
				querySnapshot.forEach((doc) => {
					const inspector = {
						id: doc.id,
						profile: { fullName: doc.data().fullName },
					};
					inspectorList.push(inspector);
				});
				employees.value = employees.value.concat(inspectorList);
			});

			const { document: companyDoc } = await getDocumentOnce(companyRef);

			company.value = companyDoc.value;
		};

		const getEquipmentAssignees = async () => {
			let companyId = action.value.companyId;
			if (
				action.value.attachedEquipment &&
				action.value.attachedEquipment.length > 0
			) {
				companyId = action.value.attachedEquipment[0].companyId;
			}
			const companyRef = projectFirestore
				.collection("companies")
				.doc(companyId);

			// Get employees of the company
			const employeesRef = companyRef
				.collection("employees")
				.where("isActive", "==", true);

			employeesRef.get().then((querySnapshot) => {
				const employeeList = [];
				querySnapshot.forEach((doc) => {
					const employee = { id: doc.id, ...doc.data() };
					employeeList.push(employee);
				});
				employees.value = employeeList;
			});

			const { document: companyDoc } = await getDocumentOnce(companyRef);

			company.value = companyDoc.value;
		};

		const assigned = computed(() => {
			if (action.value && user.value) {
				return user.value.uid == action.value.ownerId;
			}
		});

		// Used when formatting dates
		const pad = (str, length) => {
			while (str.length < length) {
				str = "0" + str;
			}
			return str;
		};

		const viewChecklist = (id) => {
			let routeData = router.resolve({
				name: "ChecklistDetail",
				params: { id: id },
			});
			window.open(routeData.href, "_blank");
		};

		const updateAction = async () => {
			updating.value = true;

			const batch = projectFirestore.batch();

			var user = employees.value.find(
				(item) => item.id == selectedUser.value
			);

			if (action.value.document) {
				// Extract plain text from Quill delta
				const plainText = quillDelta.value
					? quillDelta.value.ops.map((op) => op.insert).join("")
					: "";
				description.value = plainText;
			}

			// action update
			const update = {
				description: description.value,
				status: selectedStatus.value,
				assignedDate: dueDate.value,
				state: selectedState.value,
				assignedPerson: {
					id: user.id,
					name: user.profile.fullName,
				},
			};

			if (action.value.document) {
				// Convert Quill delta to JSON
				const deltaJson = JSON.stringify(quillDelta.value.ops);

				update.document = deltaJson;
			}

			if (
				action.value.commentors &&
				!action.value.commentors.includes(user.id)
			) {
				update.commentors = addArrayItem(user.id);
			}

			// Update the action
			batch.update(actionRef, update);

			// thread update
			if (thread.value) {
				const threadRef = projectFirestore
					.collection("threads")
					.doc(thread.value.id);

				const threadUpdate = {
					name: description.value,
					actionAssigneeId: user.id,
					actionFormattedDueDate: dueDate.value,
					actionState: selectedState.value,
					actionStatus: selectedStatus.value,
				};

				if (!thread.value.users.includes(user.id)) {
					threadUpdate.users = addArrayItem(user.id);
				}

				batch.update(threadRef, threadUpdate);
			}
			try {
				await batch.commit();
				updating.value = false;
				if (window.history.length > 1) {
					router.go(-1);
				} else {
					window.close();
				}
			} catch (err) {
				console.error(err);
				updating.value = false;
			}
		};

		const createChatThread = async () => {
			// initial users
			let users = [];

			employees.value.forEach((employee) => {
				users.push(employee.id);
			});

			const threadId = await createThread(
				props.id,
				props.collection,
				users
			);

			if (threadId) {
				// get the thread
				const threadRef = projectFirestore
					.collection("threads")
					.doc(threadId);

				const { document: threadDoc } = await getDocumentOnce(
					threadRef
				);

				thread.value = threadDoc.value;
			}
		};

		const commentorOptions = computed(() => {
			return allUsers.value.filter(
				(option) =>
					!commentors.value.some(
						(commentor) => commentor.id === option.id
					)
			);
		});

		const allCommentors = computed(() => {
			return commentors.value
				.map((commentor) => commentor.name)
				.join(", ");
		});

		const handleShowUpdateIcons = () => {
			if (showUpdateIcons.value) {
				showUpdateIcons.value = false;
			} else {
				showUpdateIcons.value = true;
			}
		};

		const handleUpdateCommentorType = (type) => {
			handleShowUpdateIcons();
			if (type == "add") {
				showAddCommentor.value = true;
			} else if (type == "remove") {
				showAddCommentor.value = false;
			}
		};

		const updateCommentor = async (actionType) => {
			try {
				updatingCommentors.value = true;
				const batch = projectFirestore.batch();

				let threadUpdate = {
					users:
						actionType === "add"
							? addArrayItem(value.value.id)
							: removeArrayItem(value.value.id),
				};

				const threadDocRef = projectFirestore
					.collection("threads")
					.doc(thread.value.id);

				batch.update(threadDocRef, threadUpdate);

				let actionUpdate = {
					users:
						actionType === "add"
							? addArrayItem(value.value.id)
							: removeArrayItem(value.value.id),
				};

				batch.update(actionRef, actionUpdate);

				await batch.commit();

				if (actionType === "add") {
					commentors.value.push(value.value);
				} else {
					commentors.value = commentors.value.filter(
						(user) => user.id !== value.value.id
					);
				}
			} catch (error) {
				console.error(error);
			} finally {
				updatingCommentors.value = false;
				value.value = [];
				handleShowUpdateIcons();
			}
		};

		return {
			action,
			options,
			description,
			selectedState,
			selectedStatus,
			selectedUser,
			dueDate,
			company,
			employees,
			owner,
			thread,

			viewChecklist,
			updateAction,
			assigned,

			updating,
			checklist,
			checklistPage,
			checklistSection,
			checklistQuestion,
			equipment,
			attachedEquipment,
			canEditEquipment,
			createChatThread,
			updateCommentor,
			commentorOptions,
			value,
			handleShowUpdateIcons,
			showUpdateIcons,
			handleUpdateCommentorType,
			showAddCommentor,
			commentors,
			updatingCommentors,
			allCommentors,
			quillDelta,
			toolbarOptions,
		};
	},
});
</script>

<style lang="scss" scoped>
.truncate {
	max-width: 210px; /* Adjust to your needs */
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
}

.edit-selected-users {
	padding-top: 12px;
	text-align: right !important;
}

.selected-users {
	padding-left: 0.5em;
	.tooltip {
		text-decoration: underline;
		cursor: pointer;
	}
}

.loading-container {
	text-align: right;
}

.modal-title {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	//width: 35ch;
	// width: 20ch;
	overflow: hidden;
	//  padding: 0 1rem;
	h3 {
		margin-left: 1em;
	}
	p {
		margin-left: 1em;
		padding-top: 2px;
	}
}
.rm-form {
	width: 95vw !important;
	max-width: 95vw !important;

	// height: 80vh !important;
	max-height: 95vh !important;

	// overflow: scroll;

	@include respond(small) {
		max-width: 75vw;
	}
	@include respond(medium) {
		max-width: 65vw;
	}
	@include respond(large) {
		max-width: 50vw;
	}
}
.container-left {
	padding: 1em 2.5em;
	height: 80vh;
	overflow: auto;
}
.split {
	flex-direction: column !important;

	@include respond(medium) {
		flex-direction: row !important;
	}
}
.form-options {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 1em;

	.btn-icon {
		font-size: 14px;

		@include respond(small) {
			font-size: 1em;
		}
	}
}

textarea {
	width: 100%;
	min-height: 4em;
	padding: 0.6em 0.8em !important;
	font-family: "Poppins";
	color: $rm-dark-blue;
	font-size: 1.2em;
	font-weight: 600;
	background-color: $rm-off-white;
	border: none;
	border-radius: $border-radius !important;
}

.action-detail {
	margin-left: 0.6em;
	margin-bottom: 1em;
	.semi-bold {
		font-weight: 600;
	}

	.margin-top {
		margin-top: 0.5em;
	}
}
.critical {
	background-color: $rm-danger;
}
.timeCritical {
	background-color: $rm-warning;
}
.nonCritical {
	background-color: $rm-dark-blue;
}
.action-icon {
	margin-top: -2px;
}
.link {
	display: flex;
	justify-content: space-between;
	align-items: center;
	&:hover {
		color: $rm-pink;
		cursor: pointer;
	}
	.icon {
		margin-top: -5px;
	}
}

.attached-equipment-container {
	background-color: $rm-off-white;
	border: 1px solid $rm-light-grey;
	border-radius: $border-radius;
	padding: 0.5em;
	max-height: 19vh;
	overflow: auto;
}

.images {
	.image-grid {
		display: grid;
		margin-top: 0.5em;
		padding: 1em;
		grid-template-columns: repeat(5, 100px);
		grid-template-rows: auto;
		column-gap: 1em;
		row-gap: 1em;
		justify-items: center;
		align-items: center;
		background-color: $rm-off-white;
		border-radius: $border-radius;

		.image {
			img {
				width: 100px;
				height: 100px;
				object-fit: cover;
				overflow: hidden;
			}
		}
	}
}

.start-chat {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	align-items: center;
}
</style>
