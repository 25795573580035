import {
	projectFirestore,
	projectAuth,
	projectStorage,
	timestamp,
} from "../firebase/config";
import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";
import useCookies from "./useCookies";

const chatService = () => {
	const db = projectFirestore;
	const user = projectAuth.currentUser;
	const { getCookie } = useCookies();
	const companyId = getCookie("rm_cId");

	const error = ref(null);
	const isPending = ref(false);

	const createThread = async (
		actionId,
		actionCollection,
		selectedUserIds
	) => {
		error.value = null;

		let action;

		try {
			// Get the action from Firebase
			const actionRes = await db
				.collection(actionCollection)
				.doc(actionId)
				.get();

			if (actionRes.exists) {
				action = { id: actionId, ...actionRes.data() };
			}
		} catch (e) {
			error.value = "Could not get the action";
			return null;
		}

		const threadId = uuidv4();

		let actionIsEquipment =
			actionCollection == "EquipmentAction" ? true : null;

		// Create a thread object
		let thread = {
			name: action["description"],
			id: threadId,
			isGroup: true,
			users: selectedUserIds,
			companyId: companyId,
			actionId: action["id"],
			actionOwnerId: action["ownerId"],
			actionAssigneeId: action["assignedPerson"]["id"],
			actionIsEquipment: actionIsEquipment,
			actionState: action["state"],
			actionStatus: action["status"],
			actionFormattedDueDate: action["assignedDate"],
		};

		let batch = db.batch();

		let threadRef = db.collection("threads").doc(threadId);

		let actionRef;

		if (actionIsEquipment != null && actionIsEquipment) {
			actionRef = db.collection("EquipmentAction").doc(action["id"]);
		} else {
			actionRef = db.collection("Action").doc(action["id"]);
		}

		batch.set(threadRef, thread);
		batch.update(actionRef, { commentors: selectedUserIds });

		try {
			await batch.commit();
			console.log("Thread created successfully!");
			console.log(`Thread ID: ${threadId}`);
			return threadId;
		} catch (e) {
			error.value = "Could not create the chat thread";
			return null;
		}
	};

	const sendText = async (threadId, message) => {
		error.value = null;
		isPending.value = true;

		let defaultProviderData;

		user.providerData.forEach((profile) => {
			if (profile.providerId === "password") {
				defaultProviderData = profile;
				console.log(defaultProviderData);
			}
		});

		const batch = db.batch();

		try {
			const messageId = uuidv4();

			// Create a new message document
			const newMessage = {
				threadId: threadId,
				messageId: messageId,
				idFrom: user.uid,
				timestamp: timestamp(),
				nameFrom: defaultProviderData.displayName,
				type: 0,
				content: message,
				downloadUrl: "",
				videoThumbnail: "",
				photoFrom:
					defaultProviderData.photoURL == null
						? ""
						: defaultProviderData.photoURL,
			};

			const messageRef = db
				.collection("threads")
				.doc(threadId)
				.collection("messages")
				.doc(messageId);

			// Add the new message to the thread collection in Firebase
			batch.set(messageRef, newMessage);

			// Update the thread document in Firebase
			const threadRef = db.collection("threads").doc(threadId);

			const threadUpdate = {
				lastMessage: message,
				lastMessageFrom: user.displayName,
				lastMessageFromId: user.uid,
				lastMessageTime: timestamp(),
			};

			batch.update(threadRef, threadUpdate);

			await batch.commit();

			isPending.value = false;
		} catch (e) {
			isPending.value = false;
			error.value = "Could not send the message";
		}
	};

	async function sendFile(threadId, folder, file) {
		error.value = null;
		isPending.value = true;

		var type = 1;

		if (folder == "images") {
			type = 1;
		} else if (folder == "videos") {
			type = 2;
		} else if (folder == "files") {
			type = 3;
		}

		const batch = db.batch();

		try {
			const messageId = uuidv4();

			// Upload the file to Firebase Storage
			const bucket = `threads/${threadId}/${folder}`;
			const result = await uploadDocumentToFirebase(bucket, file);

			// Create a new message document
			const message = {
				threadId: threadId,
				messageId: messageId,
				idFrom: user.uid,
				timestamp: timestamp(),
				nameFrom: user.displayName,
				type: type,
				content: type == 3 ? result.content : "",
				downloadUrl: result.downloadUrl,
				videoThumbnail: "",
				photoFrom: user.photoURL,
			};

			// Add the new message to the thread collection in Firebase
			const messageRef = db
				.collection("threads")
				.doc(threadId)
				.collection("messages")
				.doc(messageId);
			batch.set(messageRef, message);

			// Update the thread document in Firebase
			const threadRef = db.collection("threads").doc(threadId);

			let lastMessage = "";

			if (type == 1) {
				lastMessage = "[Image]";
			} else if (type == 2) {
				lastMessage = "[Video]";
			} else if (type == 3) {
				lastMessage = "[File]";
			} else {
				lastMessage = result.content;
			}

			const threadUpdate = {
				lastMessage: lastMessage,
				lastMessageFrom: user.displayName,
				lastMessageFromId: user.uid,
				lastMessageTime: timestamp(),
			};

			batch.update(threadRef, threadUpdate);

			await batch.commit();

			isPending.value = false;
		} catch (e) {
			console.error(e);
			error.value = e;
			isPending.value = false;
		}
	}

	const uploadDocumentToFirebase = async (bucket, file) => {
		try {
			const storageRef = projectStorage.ref();

			const fileName = file.name.split(".").slice(0, -1).join(".");
			const fileExtension = file.name.split(".").pop();

			const fileRef = storageRef.child(
				`${bucket}/${fileName}.${fileExtension}`
			);

			const snapshot = await fileRef.put(file);
			const downloadUrl = await snapshot.ref.getDownloadURL();

			const result = {
				content: `${fileName}.${fileExtension}`,
				downloadUrl: downloadUrl,
			};

			return result;
		} catch (e) {
			console.error(e);
			throw new Error(e);
		}
	};

	const deleteMessage = async (threadId, messageId) => {
		try {
			// Delete the message from the thread collection in Firebase
			await db
				.collection("threads")
				.doc(threadId)
				.collection("messages")
				.doc(messageId)
				.delete();

			console.log("Message deleted successfully!");
		} catch (error) {
			console.error("Error deleting message:", error);
		}
	};

	return {
		createThread,
		sendText,
		sendFile,
		deleteMessage,
		error,
		isPending,
	};
};

export default chatService;
